@import '../../assets/styles/mixins/main-text-font';

.brand-banner {
  background-image: url('../../assets/images/brandbanner-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 480px;
  box-sizing: border-box;
  padding-top: 55px;

  @media screen and (max-width: 1220px) {
    height: 289px;
    padding-top: 30px;
  }

  @media screen and (max-width: 720px) {
    height: 237px;
    padding-top: 20px;
    background-position: center right -13px;
  }

  &__container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__subtitle {
    color: var(--main-text-color);
    @include main-text-font(28, 700, 1.2);

    margin: 0 137px 0 0;
    padding: 0;
    width: 393px;

    @media screen and (max-width: 1220px) {
      margin-right: 39px;
      font-size: 18px;
      width: 276px;
    }

    @media screen and (max-width: 720px) {
      width: 251px;
      margin-right: 15px;
    }
  }

  &__title {
    color: var(--main-text-color);
    @include main-text-font(40, 700, 1.05);

    margin: 10px 0 25px 0;
    padding: 0;
    width: 530px;

    @media screen and (max-width: 1220px) {
      width: 315px;
      font-size: 24px;
      margin-top: 3px;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 720px) {
      width: 251px;
      font-size: 22px;
      line-height: normal;
      margin-top: 5px;
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }
}
