.close-button {
  background-color: var(--main-background-color);
  background: transparent;
  background-image: url('../../assets/icons/search-close-button.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 19px;
  height: 19px;
  display: inline-block;

  &_place_search {
    margin-top: 26px;
    transition: opacity 0.3s linear;

    @media screen and (max-width: 1262px) {
      width: 25px;
      height: 25px;
      margin-top: 22px;
    }

    @media screen and (max-width: 454px) {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &_place_burger {
    align-self: flex-end;
    margin-bottom: 26.14px;
    width: 23px;
    height: 21.722px;
  }

  &_place_popup {
    width: 30px;
    height: 30px;
    background-image: url('../../assets/icons/report-popup-close-button.svg');
    background-size: contain;
    background-position: center;
    margin-bottom: 35px;
    align-self: flex-end;
    transition: opacity 0.5s linear;

    &:hover {
      opacity: 0.8;
    }
  }
}
