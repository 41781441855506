.burger-open-button {
  width: 28px;
  height: 20px;
  background-image: url('../../assets/icons/header-burger-open-button.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0 0 41px;
  padding: 0;
  display: none;

  @media screen and (max-width: 1126px) {
    display: inline-block;
  }

  @media screen and (max-width: 436px) {
    margin-left: 0;
    width: 30.002px;
    height: 22px;
  }
}
