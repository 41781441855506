.search {
  background-color: #fff;

  &_place_header {
    @media screen and (max-width: 436px) {
      display: none;
    }
  }

  &_place_search-results {
    display: none;

    @media screen and (max-width: 436px) {
      display: block;
      margin-bottom: 14px;
    }
  }

  &__container {
    max-width: 1280px;
    visibility: hidden;
    opacity: 0;
    display: flex;
    height: 0;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 85px 0 86px;
    box-sizing: border-box;
    transition: height 0.3s ease-in-out, visibility 0.1s ease-in-out, opacity 0.3s ease-in-out;

    @media screen and (max-width: 1262px) {
      padding-right: 39px;
      padding-left: 38px;
    }

    @media screen and (max-width: 546px) {
      padding-right: 15px;
      padding-left: 15px;
    }

    &_is_opened {
      visibility: visible;
      height: 83px;
      opacity: 1;

      @media screen and (max-width: 1262px) {
        height: 86px;
      }
    }

    &_place_search {
      padding: 0;

      @media screen and (max-width: 546px) {
        padding-left: 5px;
      }
    }

    &_place_search-results {
      transition: none;

      @media screen and (max-width: 546px) {
        padding-right: 0;
        padding-left: 5px;
        height: 80px;
      }
    }
  }

  &__form {
    width: 473px;
    height: 57px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 18px;

    @media screen and (max-width: 1126px) {
      width: 393px;
    }

    @media screen and (max-width: 436px) {
      width: 290px;
    }
  }

  &__label {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
  }

  &__input {
    width: 100%;
    height: 35px;
    border: none;
    outline: 1px solid #62767c;
    border-radius: 3px;
    color: #282f30;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    box-sizing: border-box;
    padding: 0 71px 0 10px;
    margin-bottom: 8px;

    @media screen and (max-width: 546px) {
      padding-right: 77px;
    }

    &:not(.search__input_type_error):focus {
      outline: 2px solid #909da1;
    }

    &::placeholder {
      color: #bdbdbd;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media screen and (max-width: 546px) {
        font-size: 12px;
      }
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      display: none;
    }

    &_type_error {
      outline: 1px solid #eb5757;
    }
  }

  &__error {
    height: 14px;
    color: #eb5757;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    display: inline-block;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 546px) {
      font-size: 10px;
    }
  }

  &__buttons {
    display: flex;
    gap: 15px;
    position: absolute;
    right: 8px;
    top: 8px;

    @media screen and (max-width: 1262px) {
      gap: 12px;
    }

    @media screen and (max-width: 546px) {
      gap: 10px;
    }
  }
}
