.pagination {
  display: flex;
  align-items: center;
  gap: 33px;
  font-family: Manrope;

  @media screen and (max-width: 1126px) {
    display: none;
  }

  &__button {
    color: #fcbe13;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 23.4px */
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__list-item {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    &_type_ellipsis {
      margin-right: 13.5px;
    }
  }

  &__page-number-button {
    color: #282f30;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* Фиксированная высота строки */
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    border-radius: 100%;
    margin: 0;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    transition: color 0.3s linear;

    &:not(.pagination__page-number-button_active):hover {
      color: #fcbe13;
    }

    &_active {
      color: #fff;
      background-color: #fcbe13;
      text-align: center;
      transition: none;
    }
  }
}
