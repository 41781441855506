.header-icons {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 0;

  @media screen and (max-width: 1126px) {
    margin-top: 22.2px;
  }

  @media screen and (max-width: 546px) {
    margin-top: 26.5px;
  }

  &__item {
    margin-right: 20px;

    &:nth-child(3) {
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 1126px) {
      margin-right: 26px;
    }

    &_place_search {
      &:not(:last-child) {
        display: none;
      }
    }
  }
}
