@import '../../assets/styles/mixins/main-text-font';

.tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  overflow-x: auto;

  @media screen and (max-width: 622px) {
    gap: 4px;
  }

  &_place_scores {
    @media screen and (max-width: 546px) {
      margin-left: 5px;
    }
  }

  &_place_full-union-member {
    @media screen and (max-width: 622px) {
      margin-left: 15px;
    }
  }

  &_type_right {
    @media screen and (max-width: 622px) {
      margin-left: 0;
    }
  }

  &__button {
    border: none;
    border-radius: 5px;
    width: 218px;
    height: 50px;
    background: #fafafa;
    backdrop-filter: blur(6px);
    color: #282f30;
    @include main-text-font(14, 400, 1.25);

    margin: 0;
    padding: 0;

    @media screen and (max-width: 1164px) {
      width: 175px;
      height: 40px;
      font-size: 14px;
      line-height: 1.2;
    }

    @media screen and (max-width: 622px) {
      width: 174.4px;
      font-size: 12px;
      line-height: normal;
    }

    &_active {
      background: #f8e2a6;

      @media screen and (min-width: 1164px) {
        font-weight: 500;
      }

      @media screen and (max-width: 622px) {
        font-weight: 500;
      }
    }
  }
}
