@import '../../assets/styles/mixins/main-text-font';

.logo {
  display: flex;
  text-decoration: none;
  font-size: 0;
  margin-bottom: 10px;
  padding: 0;

  &:hover .logo__title {
    color: #62767c;
  }

  @media screen and (max-width: 1126px) {
    margin-bottom: 0;
    align-self: center;
  }

  &_place_footer {
    &:hover .logo__title_place_footer {
      color: var(--active-link-color);
    }

    @media screen and (max-width: 1126px) {
      align-self: flex-start;
    }

    @media screen and (max-width: 726px) {
      margin-bottom: 0;
    }
  }

  &_place_brand-banner {
    margin-right: 164px;
    margin-bottom: 0;
    display: inline-block;

    @media screen and (max-width: 1126px) {
      align-self: flex-end;
    }

    @media screen and (max-width: 950px) {
      margin-right: 98px;
    }

    @media screen and (max-width: 637px) {
      margin-right: 41.84px;
    }
  }

  &_place_admin {
    margin-inline: auto;
    width: max-content;
  }

  &__image {
    width: 31px;
    height: 38px;
    font-size: 0;
    display: inline-block;

    @media screen and (max-width: 1126px) {
      width: 25.289px;
      height: 31px;
    }

    &_place_main {
      width: 175.395px;
      height: 215px;

      @media screen and (max-width: 1255px) {
        width: 98px;
        height: 120.129px;
      }

      @media screen and (max-width: 720px) {
        width: 81.579px;
        height: 100px;
      }
    }

    &_place_contacts {
      width: 142px;
      height: 174.065px;
    }
  }

  &__title {
    color: #282f30;
    @include main-text-font(12, 500);

    text-transform: uppercase;
    margin: 3px 0 0 10px;
    padding: 0;
    transition: color 0.2s ease-in-out;

    @media screen and (max-width: 1126px) {
      font-size: 10px;
      margin-top: 1px;
    }

    @media screen and (max-width: 436px) {
      font-size: 12px;
      margin-top: 3px;
    }

    &_place_footer {
      color: var(--footer-text-color);
      transition: color 0.3s ease-in-out;
    }
  }
}
