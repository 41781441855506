.union-member-skeleton {
  width: 541px;
  height: 413px;

  @media screen and (max-width: 891px) {
    width: 335.8px;
    height: 263px;
  }

  @media screen and (max-width: 482px) {
    width: 290px;
    height: 240px;
  }

  &__iframe {
    width: 100%;
    height: 303px;

    @media screen and (max-width: 891px) {
      height: 188px;
    }

    @media screen and (max-width: 482px) {
      height: 162.4px;
    }
  }

  &__title {
    width: 100%;
    height: 44px;

    @media screen and (max-width: 891px) {
      height: 36px;
    }

    @media screen and (max-width: 482px) {
      height: 38px;
    }
  }

  &__profession {
    width: 100%;
    height: 21px;

    @media screen and (max-width: 891px) {
      height: 16px;
    }
  }
}
