@import '../../assets/styles/mixins/main-text-font';

.heading {
  margin: 0 0 50px;
  padding: 0;

  @include main-text-font(28, 700, 1.05);

  color: #282f30;

  @media screen and (max-width: 1164px) {
    font-size: 22px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 622px) {
    font-size: 20px;
    line-height: 120%; /* 24px */
    margin-bottom: 25px;
  }

  &_type_our-history {
    min-height: 33px;

    @media screen and (max-width: 1164px) {
      min-height: auto;
    }
  }

  &_level_3 {
    font-size: 20px;
    margin-top: 17px;
    margin-bottom: 25px;
    line-height: 110%; /* 22px */

    @media screen and (max-width: 1164px) {
      font-size: 18px;
      margin-top: 10px;
    }

    @media screen and (max-width: 622px) {
      font-size: 16px;
      line-height: 1.25;
      margin-top: 14.2px;
    }
  }

  &_type_about-us {
    min-height: 23px;

    @media screen and (max-width: 1164px) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 622px) {
      min-height: auto;
      margin-bottom: 15px;
    }
  }

  &_level_4 {
  }
}
