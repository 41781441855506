.timeline-container {
  width: 521px;
  height: 22px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 810px) {
    width: 334px;
  }

  @media screen and (max-width: 612px) {
    width: 91px;
  }

  &_width_volumeline-container-hover {
    max-width: 521px;
  }

  &__timeline {
    width: 100%;
    height: 4px;
    background-color: #d9d9d9;
    border-radius: 200px;
    transition: all 0.3s linear;

    &_type_volume {
      width: 0;
    }

    &_hovered {
      width: 110px;
      margin-right: 12px;
    }
  }
}
