.audio-recordings {
  list-style: none;
  margin: 50px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 50px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1164px) {
    margin-top: 40px;
    gap: 35px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 546px) {
    padding-left: 5px;
    padding-right: 15px;
  }

  &__item-title {
    color: var(--main-text-color);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3;
    margin: 0 0 25px;
    padding: 0;

    @media screen and (max-width: 1164px) {
      font-size: 16px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}
