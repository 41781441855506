.share-panel {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
  height: 50px;
  align-items: center;
  font-size: 0;

  @media screen and (max-width: 1164px) {
    height: 45px;
  }

  @media screen and (max-width: 546px) {
    margin-left: 15px;
  }

  &__button {
    border: none;
    width: 30px;
    height: 30px;
    background: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &__link {
    width: 30px;
    height: 30px;
  }
}
