.speed-params-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 15px;
  right: 35px;
  list-style-type: none;
  display: none;
  width: 115px;
  height: 200px;
  overflow-y: auto;

  @media screen and (max-width: 612px) {
    width: fit-content;
    height: 40px;
    right: -15px;
    overflow: hidden;
  }

  &_is_opened {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 612px) {
      flex-direction: row;
    }
  }

  &__item {
    &:first-of-type {
      @media screen and (max-width: 612px) {
        display: none;
      }
    }

    &:nth-of-type(5) {
      @media screen and (max-width: 612px) {
        display: none;
      }
    }

    &:nth-of-type(6) {
      display: none;

      @media screen and (max-width: 612px) {
        display: block;
      }
    }
  }

  &__button {
    border: none;
    box-sizing: border-box;
    padding: 0 0 0 12px;
    width: 100%;
    height: 32px;
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    background-color: #fafafa;
    text-align: left;
    display: inline-block;

    @media screen and (max-width: 612px) {
      line-height: normal;
      width: 36px;
      height: 40px;
      font-size: 12px;
      padding-left: 0;
      text-align: center;
    }

    &:hover {
      background-color: #f8e2a6;
    }
  }
}
