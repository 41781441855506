@import '../../assets/styles/mixins/main-text-font';

.news-block {
  display: flex;
  flex-direction: column;
  width: 350px;
  font-size: 0;

  @media screen and (max-width: 1169px) {
    width: 216px;
  }

  @media screen and (max-width: 767px) {
    width: 270px;
  }

  &__link {
    width: 100%;
    font-size: 0;
  }

  &__image {
    width: 100%;
    height: 205px;
    object-fit: cover;
    object-position: top -37px right 0;

    @media screen and (max-width: 1169px) {
      height: 123px;
    }

    @media screen and (max-width: 767px) {
      height: 158.143px;
    }
  }

  &__date {
    color: #282f30;
    @include main-text-font(14, 400, 1.25);
    margin: 20px 0 15px 0;
    padding: 0;
    min-height: 18px;

    @media screen and (max-width: 1169px) {
      font-size: 12px;
      line-height: 1.3; /* 15.6px */
      margin-top: 10px;
      margin-bottom: 10px;
      min-height: 16px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 15px;
      line-height: normal;
      height: 16px;
    }
  }

  &__title {
    color: var(--main-text-color);
    @include main-text-font(20, 700);
    margin: 0;
    padding: 0;
    min-height: 54px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1169px) {
      font-size: 14px;
      min-height: 35px;
      line-height: 1.2; /* 16.8px */
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
      min-height: 42px;
      line-height: 1.3;
    }
  }

  &__text {
    color: var(--main-text-color);
    @include main-text-font(16, 400, 1.3);
    margin: 10px 0 40px 0;
    padding: 0;
    height: 69px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1169px) {
      -webkit-line-clamp: 4;
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 18px;
      height: 62px;
    }

    @media screen and (max-width: 767px) {
      -webkit-line-clamp: 3;
      height: 53.229px;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 25px;
      line-height: normal;
    }
  }
}
