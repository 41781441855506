@import '../../assets/styles/mixins/main-text-font';

.text-content {
  list-style-type: none;
  margin: 40px 0 0;
  padding: 0;

  &_place_about-us {
    margin-top: 50px;
    max-width: 953px;

    @media screen and (max-width: 1164px) {
      margin-top: 40px;
      max-width: 94.35%;
    }

    @media screen and (max-width: 546px) {
      margin-top: 30px;
      padding-left: 25px;
      max-width: 88.53%;
    }
  }

  &_place_full-union-member {
    max-width: 885px;

    @media screen and (max-width: 1164px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 960px) {
      max-width: 651px;
    }

    @media screen and (max-width: 622px) {
      padding-left: 15px;
      padding-right: 25px;
    }
  }

  &_place_full-project {
    max-width: 855px;
    margin-bottom: 60px;

    @media screen and (max-width: 1180px) {
      margin-top: 30px;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  &_place_full-article {
    max-width: 950px;

    @media screen and (max-width: 1028px) {
      max-width: 651px;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
      margin-top: 30px;
    }
  }

  &__item:last-child {
    .text-content__paragraph {
      margin-bottom: 0;
    }
  }

  &__paragraph {
    margin: 0 0 23px;
    padding: 0;
    color: #282f30;
    @include main-text-font(18, 400, 1.3);

    display: inline-block;

    @media screen and (max-width: 1164px) {
      font-size: 16px;
      margin-bottom: 23px;
    }

    @media screen and (max-width: 622px) {
      font-size: 14px;
      line-height: normal;
      margin-bottom: 16.8px;
    }

    &_place_full-project {
      font-size: 16px;

      @media screen and (max-width: 546px) {
        font-size: 14px;
      }
    }

    &_place_full-article {
      margin-bottom: 21.8px;

      @media screen and (max-width: 622px) {
        margin-bottom: 16.5px;
      }
    }

    &_place_about-us {
      font-family: 'Inter', 'Helvetica Neue', 'Arial', sans-serif;

      @media screen and (max-width: 1164px) {
        font-family: 'Manrope', 'Helvetica Neue', 'Arial', sans-serif;
      }
    }

    &_font_bold {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 22px;

      @media screen and (max-width: 1169px) {
        font-size: 16px;
      }

      @media screen and (max-width: 546px) {
        font-size: 14px;
      }
    }
  }
}
