.vk-link {
  padding: 0;
  margin: 0;
  display: inline-block;

  @media screen and (max-width: 436px) {
    display: none;
  }

  &__icon {
    @media screen and (max-width: 1220px) {
      width: 18px;
      height: 18px;
    }

    path {
      transition: all 0.3s linear;
    }

    &:hover path {
      fill: #fcbe13;
      stroke: #fcbe13;
    }

    &_place_footer {
      width: 20px;
      height: 20px;

      path {
        fill: white;
      }
    }
  }
}
