.volume-button {
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &__icon {
    g path {
      transition: fill 0.3s linear;
    }

    &:hover {
      g path {
        fill: #fcbe13;
      }
    }
  }

  &__icon-muted {
    g path {
      transition: all 0.3s linear;
    }

    &:hover {
      g path {
        stroke: #fcbe13;
        fill: #fcbe13;
      }
    }
  }
}
