:root {
  /*   text-colors */
  --main-text-color: #282f30;
  --default-text-color: #000;
  --active-link-color: #fcbe13;
  --hover-off-active-link-color: #62767c;
  --crumb-link-color: #909da1;
  --crumb-link-hover-color: #b4bec1;
  --footer-text-color: #fff;

  /* border-colors */
  --main-border-color: #000;
  --main-cta-border-color: #fcbe13;
  --footer-background-color: #303a3d;

  /*background-colors*/
  --main-background-color: #fff;
  --tab-background-color: #fafafa;

  /*fonts*/
}
