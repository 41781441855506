.search-button {
  width: 19px;
  height: 19px;
  background-color: #fff;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  display: flex;

  @media screen and (max-width: 1220px) {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &_place_header {
    @media screen and (max-width: 436px) {
      display: none;
    }

    svg path {
      transition: stroke 0.3s linear;
    }

    &:hover svg path {
      stroke: #fcbe13;
    }
  }

  &_place_burger {
    width: 100%;
    margin-bottom: 32px;
    display: none;

    @media screen and (max-width: 436px) {
      display: flex;
    }
  }

  &_place_search {
    @media screen and (max-width: 1220px) {
      width: 19px;
      height: 19px;

      svg {
        width: 19px;
        height: 19px;
      }
    }
  }

  &__text {
    color: #282f30;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
  }
}
