@import './assets/styles/mixins/page-flex-container';
@import './assets/styles/mixins/main-text-font';

.page {
  background: var(--main-background-color);
  color: var(--default-text-color);
  font-family: 'Manrope', 'Helvetica Neue', 'Arial', sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;

  &__content {
    min-width: 320px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.home {
  padding-bottom: 120px;
  width: 100%;

  @media screen and (max-width: 1169px) {
    padding-bottom: 90px;
  }

  @media screen and (max-width: 638px) {
    padding-bottom: 60px;
  }
}

.news {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 25px 60px 10px;
  }
}

.full-news {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 546px) {
    padding-top: 25px;
    padding-right: 25px;
    padding-left: 10px;
  }

  &__date {
    margin: 0 0 40px;
    padding: 0;
    display: inline-block;
    color: var(--main-text-color);
    @include main-text-font(20, 700, 1.2);

    @media screen and (max-width: 1169px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1126px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
      line-height: normal;
    }

    @media screen and (max-width: 436px) {
      margin-bottom: 20px;
    }
  }

  &__image {
    width: 1110px;
    height: 600px;
    object-fit: cover;
    object-position: top -100px right 0;

    @media screen and (max-width: 1188px) {
      width: 690px;
      height: 373px;
    }

    @media screen and (max-width: 767px) {
      width: 270px;
      height: 146px;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
    }
  }

  &__text {
    margin: 40px 0 60px;
    padding: 0;
    max-width: 855px;
    color: var(--main-text-color);
    @include main-text-font(18, 400, 1.3);

    @media screen and (max-width: 1126px) {
      margin-top: 30px;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
    }

    @media screen and (max-width: 572px) {
      font-size: 14px;
      line-height: normal;
    }

    @media screen and (max-width: 436px) {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
}

.union-members {
  @include page-flex-container(60px, 85px, 90px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 15px 60px 10px;
  }

  &__title {
    margin: 0 0 50px 0;
    padding: 0;
  }

  &__list {
    margin: 0 0 90px;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    gap: 40px 30px;

    @media screen and (max-width: 1126px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 891px) {
      grid-template-columns: repeat(auto-fit, minmax(217px, 1fr));
    }

    @media screen and (max-width: 546px) {
      padding-left: 5px;
    }

    @media screen and (max-width: 482px) {
      grid-template-columns: repeat(auto-fit, minmax(137px, 1fr));
      justify-items: center;
    }

    &_gap_mobile {
      gap: 30px 19px;

      @media screen and (max-width: 482px) {
        gap: 20px 16px;
      }
    }
  }
}

.full-union-member {
  @include page-flex-container(60px, 85px, 200px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 546px) {
    padding-top: 25px;
    padding-right: 0;
    padding-left: 10px;
  }

  &__container {
    margin-bottom: 45px;
    display: grid;
    grid-template-columns: 445px 1fr;
    grid-template-rows: 306px 139px;
    column-gap: 95px;

    @media screen and (max-width: 1164px) {
      grid-template-columns: 296px 1fr;
      grid-template-rows: 261px 35px;
      column-gap: 59px;
      margin-bottom: 40px;
    }

    @media screen and (max-width: 622px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 45px;
    }
  }

  &__image {
    width: 445px;
    height: 445px;
    object-fit: cover;
    grid-row: 1/3;
    grid-column: 1/2;

    @media screen and (max-width: 1164px) {
      width: 296px;
      height: 296px;
    }

    @media screen and (max-width: 622px) {
      width: 270px;
      height: 270px;
      margin: 0 auto;
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 25px;
    }
  }

  &__short-biography {
    margin: 0 0 40px 0;
    padding: 0;
    color: var(--main-text-color);
    @include main-text-font(16, 400, 1.3);

    max-height: 266px;
    max-width: 505px;

    @media screen and (max-width: 1164px) {
      max-height: 226px;
      margin-top: 5px;
      margin-bottom: 0;
      margin-left: 2px;
      display: -webkit-box;
      -webkit-line-clamp: 11;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 622px) {
      font-size: 14px;
      line-height: normal;
      max-width: 100%;
      max-height: none;
      display: block;
      -webkit-line-clamp: none;
      overflow: visible;
      text-overflow: clip;
      margin-top: 0;
      margin-bottom: 40px;
      margin-left: 0;
      padding-left: 15px;
      padding-right: 25px;
    }
  }
}

.projects {
  @include page-flex-container(60px, 87px, 120px, 85px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 25px 100px 10px;
  }

  &__list {
    list-style: none;
    margin: 0 0 90px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 85px;

    @media screen and (max-width: 1126px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 900px) {
      gap: 50px;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
    }
  }
}

.full-project {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 25px 100px 10px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: fit-content;

    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
    }
  }

  &__image {
    width: 1110px;
    height: 600px;

    @media screen and (max-width: 1188px) {
      width: 690px;
      height: 373px;
    }

    @media screen and (max-width: 767px) {
      width: 270px;
      height: 200px;
    }
  }

  &__text {
    margin: 40px 0 60px;
    padding: 0;
    color: var(--main-text-color);
    @include main-text-font(18, 400, 1.3);

    max-width: 855px;

    @media screen and (max-width: 1188px) {
      max-width: 690px;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
      max-width: 270px;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
}

.scores {
  @include page-flex-container(60px, 85px, 197px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 0 155px 10px;
  }

  &__list {
    list-style: none;
    margin: 50px 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 1164px) {
      margin-top: 40px;
      gap: 25px;
    }

    @media screen and (max-width: 622px) {
      margin-top: 30px;
      gap: 30px;
    }

    @media screen and (max-width: 546px) {
      padding-right: 15px;
    }
  }

  &__link {
    text-decoration: none;
    color: var(--default-text-color);
    @include main-text-font(18, 500, 1.4);

    display: flex;
    gap: 22px;

    @media screen and (max-width: 1164px) {
      font-size: 16px;
      line-height: 1.3;
      gap: 13.5px;
    }

    @media screen and (max-width: 546px) {
      line-height: normal;
      padding-left: 5px;
      gap: 11.33px;
    }
  }

  &__link-image {
    background-image: url('./assets/icons/score-image.svg');
    background-size: cover;
    width: 19px;
    height: 22px;
    margin-top: 1.5px;

    @media screen and (max-width: 1164px) {
      width: 14.25px;
      height: 16.5px;
      margin-top: 2.25px;
    }

    @media screen and (max-width: 546px) {
      width: 12.667px;
      height: 14.667px;
      margin-top: 4.67px;
    }
  }
}

.media {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 170px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 0 106px 10px;
  }

  &_type_video {
    @media screen and (max-width: 1169px) {
      padding-bottom: 90px;
    }

    @media screen and (max-width: 546px) {
      padding-left: 0;
      padding-right: 15px;
      padding-bottom: 100px;
    }
  }
}

.full-video {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 175px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 0 106.17px;
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 1189px) {
      width: 690px;
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      width: 320px;
    }
  }

  &__iframe {
    width: 1109.945px;
    height: 622.802px;
    border: none;

    @media screen and (max-width: 1189px) {
      width: 688.725px;
      height: 386.451px;
    }

    @media screen and (max-width: 767px) {
      width: 319px;
      height: 179px;
    }
  }

  &__title {
    width: 100%;
    margin: 40px 0 20px;
    padding: 0;
    color: var(--default-text-color);
    @include main-text-font(28, 700, 1.05);

    @media screen and (max-width: 1189px) {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 1.2;
    }

    @media screen and (max-width: 767px) {
      margin-top: 17px;
      margin-bottom: 8.83;
      font-size: 14px;
      line-height: normal;
    }

    @media screen and (max-width: 546px) {
      box-sizing: border-box;
      padding-left: 15px;
    }
  }

  &__date {
    color: var(--default-text-color);
    @include main-text-font(20, 700, 1.2);

    @media screen and (max-width: 1189px) {
      font-size: 16px;
      line-height: 1.3;
      font-weight: 400;
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }

    @media screen and (max-width: 546px) {
      box-sizing: border-box;
      padding-left: 15px;
    }
  }

  &__description {
    color: var(--default-text-color);
    @include main-text-font(16, 400, 1.3);
    margin: 25px 0 0;
    padding: 0;

    @media screen and (max-width: 1189px) {
      margin-top: 15px;
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 11px;
      font-size: 12px;
    }

    @media screen and (max-width: 546px) {
      box-sizing: border-box;
      padding-left: 15px;
    }
  }
}

.reports {
  @include page-flex-container(60px, 0, 120px, 85px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 0 100px 10px;
  }

  &__main-content {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 546px) {
      padding-left: 5px;
    }
  }

  &__image {
    width: 825px;
    height: 1167px;
    border: 0.516px solid var(--main-border-color);
    margin: 50px 0 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 0;

    @media screen and (max-width: 1164px) {
      width: 592px;
      height: 837px;
      margin-top: 40px;
    }

    @media screen and (max-width: 670px) {
      width: 290px;
      height: 410px;
      margin-top: 30px;
    }
  }
}

.about-us {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 247px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 0 100px 10px;
  }

  &_type_our-histpry {
    @media screen and (max-width: 546px) {
      padding-right: 15px;
      padding-left: 0;
    }
  }
}

.full-article {
  @include page-flex-container(60px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 546px) {
    padding-top: 25px;
    padding-right: 25px;
    padding-left: 10px;
  }

  &__container {
    display: flex;

    @media screen and (max-width: 641px) {
      flex-direction: column;
      width: 100%;
    }

    @media screen and (max-width: 546px) {
      margin: 0 auto;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }

  &__image {
    width: 585px;
    height: 445px;
    margin-right: 50px;
    margin-bottom: 10px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 1028px) {
      width: 296px;
      height: 296px;
      margin-right: 61px;
      margin-bottom: 0;
    }

    @media screen and (max-width: 641px) {
      margin: 0 auto 20px;
    }

    @media screen and (max-width: 546px) {
      width: 270px;
      height: 200px;
    }
  }

  &__description {
    margin: 0;
    padding: 0;
    color: var(--main-text-color);
    @include main-text-font(16, 400, 1.3);

    @media screen and (max-width: 1169px) {
      margin-top: 5px;
      font-size: 16px;
      max-width: 333px;
    }

    @media screen and (max-width: 641px) {
      max-width: 100%;
    }

    @media screen and (max-width: 622px) {
      margin-top: 0;
      font-size: 14px;
      line-height: normal;
    }
  }

  &__paragraph-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.contacts {
  @include page-flex-container(60px, 84px, 355px, 85px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 366px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 25px 100px 10px;
  }
}

.search-results {
  @include page-flex-container(50px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 15px 272px 10px;
  }

  &__title {
    color: var(--main-text-color);
    @include main-text-font(28, 700, 1.05);

    margin: 0 0 50px;
    padding: 0;

    @media screen and (max-width: 933px) {
      font-size: 22px;
      margin-bottom: 40px;
    }

    @media screen and (max-width: 546px) {
      font-size: 16px;
      line-height: 125%; /* 20px */
      margin-bottom: 30px;
      padding-left: 5px;
    }

    &_type_no-results {
      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 125%; /* 20px */
        margin-bottom: 20px;
        padding-left: 5px;
      }
    }
  }

  &__no-results-text {
    color: var(--main-text-color);
    @include main-text-font(20, 400, 1.2);
    margin: 0;

    @media screen and (max-width: 933px) {
      font-size: 16px;
      line-height: 1.3;
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 1.2;
      padding-left: 5px;
      min-height: 39px;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0 0 90px;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media screen and (max-width: 1126px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 933px) {
      gap: 40px;
    }

    @media screen and (max-width: 546px) {
      gap: 30px;
      padding-left: 5px;
    }
  }
}

.search-page {
  @include page-flex-container(50px, 85px, 120px);

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 1169px) {
    padding-top: 45px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 546px) {
    padding: 25px 15px 362px 15px;
    padding-top: 25px;
    padding-right: 15px;
    padding-left: 10px;
  }
}

// * {
//   border: 1px solid red;
// }
