@import '../../assets/styles/mixins/main-text-font';

.video-recordings {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(541px, 1fr));
  gap: 60px 29px;
  margin: 50px 0 92px;
  padding: 0;

  @media screen and (max-width: 1187px) {
    grid-template-columns: repeat(2, minmax(335.8px, 1fr));
    gap: 40px 19px;
    justify-items: center;
    margin-top: 40px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    gap: 40.59px;
  }

  @media screen and (max-width: 546px) {
    box-sizing: border-box;
    padding-left: 15.54px;
    margin-top: 30px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__iframe {
    width: 540px;
    height: 303px;
    border: none;

    @media screen and (max-width: 1187px) {
      width: 335.208px;
      height: 188.089px;
    }

    @media screen and (max-width: 366px) {
      width: 289.464px;
      height: 162.421px;
    }
  }

  &__item-link {
    text-decoration: none;
  }

  &__item-title {
    width: 541px;
    min-height: 44px;
    margin: 30px 0 15px;
    padding: 0;
    color: #000;
    @include main-text-font(20, 700, 1.1);

    @media screen and (max-width: 1187px) {
      width: 335.8px;
      font-size: 14px;
      line-height: 130%; /* 18.2px */
      margin-top: 15px;
      margin-bottom: 8px;
      min-height: 36px;
    }

    @media screen and (max-width: 366px) {
      width: 290px;
      min-height: 38px;
      font-size: 14px;
      line-height: normal;
    }
  }

  &__item-date {
    color: #000;
    display: inline-block;
    @include main-text-font(16, 400, 1.3);

    @media screen and (max-width: 1187px) {
      color: #282f30;
      font-size: 12px;
    }

    @media screen and (max-width: 366px) {
      height: 16.54px;
      line-height: normal;
    }
  }
}
