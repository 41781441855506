.search-result {
  display: flex;
  flex-direction: column;
  padding: 0 0 35px;
  border-bottom: 1.5px solid #fcbe13;
  color: #282f30;
  transition: color 0.2s linear;
  text-decoration: none;

  @media screen and (max-width: 933px) {
    padding-bottom: 29px;
  }

  @media screen and (max-width: 546px) {
    padding-bottom: 20px;
  }

  &:hover {
    color: #fcbe13;
  }

  &__title {
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    margin: 0 0 15px;
    padding: 0;
    min-height: 27px;

    @media screen and (max-width: 933px) {
      font-size: 18px;
      min-height: auto;
    }

    @media screen and (max-width: 546px) {
      font-size: 14px;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
  }

  &__description {
    color: #282f30;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    max-width: 855px;
    margin: 0;
    padding: 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 49px;

    @media screen and (max-width: 933px) {
      -webkit-line-clamp: 3;
      max-width: 690px;
      min-height: 65px;
    }

    @media screen and (max-width: 546px) {
      -webkit-line-clamp: 5;
      font-size: 14px;
      line-height: normal;
    }
  }
}
