.contacts-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  margin: 27px 0 0;
  padding: 0;
  color: var(--footer-text-color);

  @media screen and (max-width: 1126px) {
    margin-top: 25px;
    gap: 12px;
  }

  @media screen and (max-width: 436px) {
    margin-top: 30px;
    gap: 20px;
  }

  &_place_contacts {
    margin-top: 0;
    gap: 35px;

    @media screen and (max-width: 546px) {
      padding-left: 5px;
      gap: 30px;
    }
  }

  &__item {
    &:nth-child(3):hover {
      a svg path {
        fill: var(--active-link-color);
      }
    }
  }
}
