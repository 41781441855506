.header {
  background-color: #fafafa;

  &__menu-container {
    max-width: 1280px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 86.3px 0 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 89px;

    @media screen and (max-width: 1262px) {
      padding-left: 40px;
      padding-right: 39px;
    }

    @media screen and (max-width: 1126px) {
      height: 65px;
      align-items: flex-start;
    }

    @media screen and (max-width: 546px) {
      height: 78px;
      padding-right: 17px;
      padding-left: 15px;
    }
  }
}
