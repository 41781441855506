@import '../../assets/styles/mixins/main-text-font';

.time-counter {
  width: 67px;
  height: 19px;
  color: #303a3d;
  @include main-text-font(14, 400);

  @media screen and (max-width: 810px) {
    width: 57px;
    height: 16px;
    font-size: 12px;
  }

  @media screen and (max-width: 612px) {
    font-size: 12px;
  }
}
