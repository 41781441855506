@import '../../assets/styles/mixins/main-text-font';

.bread-crumbs {
  background-color: var(--main-background-color);
  padding: 20px 0 0;

  @media screen and (max-width: 1126px) {
    padding-top: 0;
  }

  @media screen and (max-width: 711px) {
    display: none;
  }

  &__list {
    max-width: 1280px;
    list-style: none;
    margin: 0 auto;
    padding: 12px 85px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 50px;
    font-size: 0;

    @media screen and (max-width: 1262px) {
      padding-left: 39px;
      padding-right: 39px;
    }

    @media screen and (max-width: 1126px) {
      padding-top: 15px;
      padding-bottom: 15px;
      height: 46px;
    }
  }
}
