@import '../../assets/styles/mixins/main-text-font';

.footer {
  background: var(--footer-background-color);
  margin-top: auto; /*для того, чтобы прибить футер к низу (сделать sticky footer) */

  &__columns {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    padding: 35px 118px 65px 85px;

    @media screen and (max-width: 1262px) {
      padding-right: 58px;
      padding-left: 37px;
      padding-bottom: 60px;
    }

    @media screen and (max-width: 546px) {
      padding: 30px 42px 50px 15px;
    }

    @media screen and (max-width: 726px) {
      flex-direction: column;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_type_first {
      width: 350px;
      margin-right: 30px;

      @media screen and (max-width: 726px) {
        margin-bottom: 40px;
        margin-right: 0;
        width: 100%;
      }
    }

    &_type_second {
      margin-right: 167px;
      justify-content: start;

      @media screen and (max-width: 1020px) {
        margin-right: 60px;
      }

      @media screen and (max-width: 726px) {
        margin-right: 0;
        margin-bottom: 50px;
      }

      @media screen and (max-width: 546px) {
        margin-left: 5px;
      }
    }

    &_type_third {
      justify-content: start;

      @media screen and (max-width: 726px) {
        margin-bottom: 50px;
      }

      @media screen and (max-width: 546px) {
        margin-left: 5px;
      }
    }

    &_type_fourth {
      display: none;

      @media screen and (max-width: 726px) {
        display: block;
      }

      @media screen and (max-width: 546px) {
        margin-left: 5px;
      }
    }
  }

  &__social-icons {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px;
  }

  &__author {
    margin: 0;
    padding: 0;
    color: var(--footer-text-color);
    @include main-text-font(14, 400, 1.25);

    @media screen and (max-width: 1126px) {
      font-size: 10px;
      line-height: 130%; /* 13px */
    }

    @media screen and (max-width: 726px) {
      display: none;
    }

    &_type_shown {
      @media screen and (max-width: 726px) {
        display: block;
      }

      @media screen and (max-width: 436px) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }

  &__menu-heading {
    margin: 0;
    padding: 0;
    color: var(--footer-text-color);
    @include main-text-font(18, 400, 1.25);

    @media screen and (max-width: 1126px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
    }

    @media screen and (max-width: 436px) {
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
    }

    &_type_third-column {
      box-sizing: border-box;
      padding-left: 3px;

      @media screen and (max-width: 1126px) {
        padding-left: 0;
      }
    }
  }

  &__menu-items {
    display: grid;
    grid-template-columns: 57px 58px 63px;
    gap: 20px 65px;
    list-style: none;
    margin: 30px 0 0;
    padding: 0;
    font-size: 0;

    @media screen and (max-width: 1126px) {
      margin-top: 25px;
      grid-template-columns: 49px 50px 54px;
      gap: 15px 31px;
    }

    @media screen and (max-width: 436px) {
      margin-top: 30px;
      grid-template-columns: 57px 58px 63px;
      gap: 30px 40px;
    }
  }

  &__menu-item-link {
    text-decoration: none;
    color: var(--footer-text-color);
    @include main-text-font(14, 400, 1.25);

    transition: color 0.3s ease-in-out;

    @media (hover: hover) {
      &:hover {
        color: var(--active-link-color);
      }
    }

    @media (hover: none) {
      &:active {
        color: var(--hover-off-active-link-color);
      }
    }

    @media screen and (max-width: 1126px) {
      font-size: 12px;
      line-height: 1.3; /* 15.6px */
    }

    @media screen and (max-width: 436px) {
      font-size: 14px;
      line-height: normal;
    }
  }
}
