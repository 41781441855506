@import '../../assets/styles/mixins/main-text-font';

.contact-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--footer-text-color);
  transition: color 0.3s ease-in-out;

  @media screen and (max-width: 1126px) {
    svg {
      width: 12px;
      height: 12px;
    }

    @media screen and (max-width: 436px) {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  svg path {
    transition: all 0.3s ease-in-out;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--active-link-color);

      svg path {
        stroke: var(--active-link-color);
      }
    }
  }

  @media (hover: none) {
    &:active {
      color: var(--hover-off-active-link-color);

      svg path {
        stroke: var(--hover-off-active-link-color);
      }
    }
  }

  &__text {
    margin: 0 0 0 15px;
    padding: 0;
    @include main-text-font(14, 400, 1.25);
    display: block;

    @media screen and (max-width: 1126px) {
      font-size: 12px;
      line-height: 130%;
      margin-left: 9px;
    }

    @media screen and (max-width: 436px) {
      font-size: 14px;
      line-height: normal;
      margin-left: 15px;
    }

    &_place_contacts {
      margin-left: 20px;
      color: #303a3d;
      font-size: 18px;
      line-height: 1.4;

      @media screen and (max-width: 1126px) {
        font-size: 16px;
        line-height: 1.3;
      }

      @media screen and (max-width: 546px) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;

    &_place_contacts {
      width: 24px;
      height: 24px;

      @media screen and (max-width: 546px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
