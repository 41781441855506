@import '../../assets/styles/mixins/main-text-font';

.crumb {
  color: var(--main-text-color);
  @include main-text-font(14, 400, 1.25);

  @media screen and (max-width: 1126px) {
    font-size: 12px;
    line-height: 1.3;
  }

  &:last-of-type {
    padding-top: 2px;
    box-sizing: border-box;

    @media screen and (max-width: 1126px) {
      padding-top: 0;
    }
  }

  &__link {
    text-decoration: none;
    display: inline-block;
    color: var(--crumb-link-color);
    transition: color 0.2s linear;
    margin: 0;
    padding: 0;

    &:hover {
      color: var(--crumb-link-hover-color);
    }
  }

  &__span {
    margin-left: 20px;
    color: var(--main-text-color);
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */

    @media screen and (max-width: 1126px) {
      font-size: 12px;
      line-height: 1.3;
    }
  }
}
