.play-button {
  width: 22px;
  height: 22px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;

  &__play-icon {
    g path {
      transition: fill 0.3s linear;
    }

    &:hover {
      g path {
        fill: #fcbe13;
      }
    }
  }

  &__pause-icon {
    padding: 4px;

    rect {
      transition: fill 0.3s linear;
    }

    &:hover {
      rect {
        fill: #fcbe13;
      }
    }
  }
}
