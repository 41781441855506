.news-skeleton {
  width: 350px;
  height: 481px;

  @media screen and (max-width: 1169px) {
    width: 216px;
    height: 314px;
  }

  @media screen and (max-width: 767px) {
    width: 270px;
    height: 374px;
  }

  &__date {
    width: 70px;
    height: 18px;

    @media screen and (max-width: 1169px) {
      width: 60px;
      height: 16px;
    }
  }

  &__image {
    width: 100%;
    height: 205px;

    @media screen and (max-width: 1169px) {
      height: 123px;
    }

    @media screen and (max-width: 767px) {
      height: 158.143px;
    }
  }

  &__title {
    height: 54px;
    width: 100%;

    @media screen and (max-width: 1169px) {
      height: 35px;
    }

    @media screen and (max-width: 767px) {
      height: 42px;
    }
  }

  &__text {
    width: 100%;
    height: 69px;

    @media screen and (max-width: 1169px) {
      height: 62px;
    }

    @media screen and (max-width: 767px) {
      height: 53.229px;
    }
  }

  &__button {
    width: 190px;
    height: 50px;

    @media screen and (max-width: 1169px) {
      width: 100%;
      height: 34px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 45px;
    }
  }
}
