.volumeline-container {
  height: 22px;
  display: flex;
  align-items: center;
  position: relative;

  &__volumeline-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
    transition: margin 0.3s linear;

    &_hovered {
      margin-right: 12px;
    }
  }

  &__volumeline {
    width: 0;
    height: 4px;
    background-color: #d9d9d9;
    transition: all 0.3s linear;

    &_hovered {
      width: 110px;

      @media screen and (max-width: 810px) {
        width: 70.5px;
      }

      @media screen and (max-width: 612px) {
        width: 19.2px;
      }
    }
  }
}
