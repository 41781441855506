.overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  font-family: 'Inter', 'Helvetica Neue', 'Arial', sans-serif;
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  z-index: 10;

  &_is_opened {
    visibility: visible;
    opacity: 1;
  }

  &_place_burger {
    height: 100vh;
    position: fixed;
  }
}
