@import '../../assets/styles/mixins/main-text-font';

.project-block {
  display: grid;
  grid-template-columns: 540px 1fr;
  grid-template-rows: 71px 140px 57px;
  column-gap: 95px;
  font-size: 0;

  @media screen and (max-width: 900px) {
    grid-template-columns: 295px 1fr;
    grid-template-rows: auto;
    column-gap: 39px;
  }

  @media screen and (max-width: 622px) {
    display: flex;
    flex-direction: column;
    max-width: 270px;
    margin: 0 auto;
  }

  &__image {
    width: 540px;
    height: 268px;
    grid-column: 1/2;
    grid-row: 1/4;

    @media screen and (max-width: 900px) {
      width: 295px;
      height: 177px;
    }

    @media screen and (max-width: 622px) {
      width: 270px;
      height: 158.143px;
      margin-bottom: 15px;
    }
  }

  &__title {
    color: #000;
    @include main-text-font(20, 700, 1.2);

    margin: 0 0 20px;
    padding: 0;

    @media screen and (max-width: 900px) {
      font-size: 16px;
      margin-bottom: 10px;
      min-height: 40px;
    }

    @media screen and (max-width: 622px) {
      line-height: 1.3;
      height: 42px;
    }
  }

  &__description {
    color: #282f30;
    @include main-text-font(16, 400, 1.3);

    max-height: 66px;
    margin: 0 0 50px;
    padding: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 120%; /* 16.8px */
      height: 67px;
      margin-bottom: 25px;
      max-height: 67px;
    }

    @media screen and (max-width: 622px) {
      line-height: normal;
      -webkit-line-clamp: 3;
      height: 53.4px;
    }
  }
}
