@import '../../assets/styles/mixins/main-text-font';

.title-container {
  display: flex;
  margin-bottom: 50px;

  @media screen and (max-width: 546px) {
    margin-bottom: 40px;
  }

  &_place_main {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 65px 85px 45px;
    justify-content: space-between;

    @media screen and (max-width: 1262px) {
      padding-right: 39px;
      padding-left: 39px;
    }

    @media screen and (max-width: 1169px) {
      padding-top: 40px;
      padding-bottom: 42px;
    }

    @media screen and (max-width: 712px) {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 546px) {
      padding: 40px 19px 20px 25px;
    }
  }

  &_place_full-news {
    margin-bottom: 30px;

    @media screen and (max-width: 1126px) {
      margin-bottom: 25px;
    }
  }

  &_place_union-members {
    @media screen and (max-width: 891px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 436px) {
      margin-bottom: 31px;
    }
  }

  &_place_full-union-member {
    @media screen and (max-width: 891px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 436px) {
      margin-bottom: 30px;
    }
  }

  &_place_projects {
    @media screen and (max-width: 900px) {
      margin-bottom: 40px;
    }
  }

  &_place_full-project {
    @media screen and (max-width: 1180px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  &_place_scores {
    @media screen and (max-width: 1164px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 546px) {
      margin-bottom: 25px;
    }
  }

  &_place_full-video {
    @media screen and (max-width: 546px) {
      box-sizing: border-box;
      padding-left: 10px;
    }
  }

  &_place_reports {
    @media screen and (max-width: 1164px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 670px) {
      margin-bottom: 25px;
    }
  }

  &_place_full-article {
    @media screen and (max-width: 1164px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 670px) {
      margin-bottom: 30px;
    }
  }

  &_place_contacts {
    @media screen and (max-width: 1164px) {
      margin-bottom: 60px;
    }

    @media screen and (max-width: 546px) {
      margin-bottom: 45px;
    }
  }

  &_place_search {
    @media screen and (max-width: 546px) {
      margin-bottom: 7px;
    }
  }

  &_place_search-results {
    display: none;

    @media screen and (max-width: 436px) {
      display: flex;
      margin-bottom: 7px;
    }
  }

  &_tabs_right {
    @media screen and (max-width: 546px) {
      padding-left: 10px;
    }
  }

  &__page-title {
    margin: 0;
    padding: 0;
    color: #282f30;
    @include main-text-font(36, 700, 1.05);
    min-height: 40px;

    @media screen and (max-width: 1169px) {
      font-size: 22px;
      min-height: auto;
      height: 22px;
    }

    @media screen and (max-width: 546px) {
      font-size: 20px;
      line-height: 1.2;
      height: 48px;
    }

    &_place_main {
      margin-top: 5px;
      margin-bottom: 0;

      @media screen and (max-width: 712px) {
        margin-top: 0;
        height: auto;
      }
    }

    &_place_news {
      @media screen and (max-width: 546px) {
        height: 24px;
      }
    }

    &_place_full-news {
      margin-bottom: 0;

      @media screen and (max-width: 572px) {
        font-size: 16px;
        line-height: 1.25;
        height: 24px;
      }
    }

    &_place_full-union-member {
      @media screen and (max-width: 546px) {
        padding-top: 2px;
        font-size: 16px;
        line-height: 1.25;
        height: auto;
      }
    }

    &_place_projects {
      @media screen and (max-width: 622px) {
        height: auto;
      }
    }

    &_place_full-project {
      @media screen and (max-width: 546px) {
        height: auto;
      }
    }

    &_place_scores {
      @media screen and (max-width: 546px) {
        height: auto;
      }
    }

    &_place_full-article {
      @media screen and (max-width: 546px) {
        font-size: 16px;
        line-height: 1.25;
        height: auto;
      }
    }
  }

  &__arrow-link {
    transition: stroke 0.3s ease-in-out;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    display: none;

    @media screen and (max-width: 712px) {
      display: block;
    }

    &:active {
      svg path {
        stroke: #62767c;
      }
    }

    &_place_main {
      margin-right: 0;
      transform: rotate(180deg);
    }

    &_place_union-members {
      margin-right: 20px;
    }

    &_place_full-project {
      margin-right: 10px;
    }

    &_place_search-results {
      display: none;

      @media screen and (max-width: 436px) {
        display: block;
      }
    }
  }
}
