.news-container {
  padding: 0 85px;

  @media screen and (max-width: 1262px) {
    padding-right: 39px;
    padding-left: 39px;
  }

  @media screen and (max-width: 638px) {
    padding-right: 25px;
    padding-left: 25px;
  }

  &_place_main {
    max-width: 1280px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
  }

  &_place_news {
    padding: 0 0 92px;

    @media screen and (max-width: 1126px) {
      padding-bottom: 0;
    }

    @media screen and (max-width: 546px) {
      padding-left: 15px;
    }
  }

  &_place_aboutus {
    padding: 50px 0 92px;

    @media screen and (max-width: 1126px) {
      padding-top: 40px;
      padding-bottom: 0;
    }

    @media screen and (max-width: 546px) {
      padding-top: 30px;
      padding-left: 15px;
      padding-right: 25px;
    }
  }

  &__news-list {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, (minmax(350px, 1fr)));
    list-style: none;
    gap: 70px 30px;
    justify-items: center;

    @media screen and (max-width: 1169px) {
      grid-template-columns: repeat(3, (minmax(216px, 1fr)));
      row-gap: 45px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(auto-fit, (minmax(270px, 1fr)));
    }

    &_gap_tablet {
      column-gap: 21px;
    }

    &_place_news {
      row-gap: 72px;

      @media screen and (max-width: 1169px) {
        row-gap: 45px;
      }
    }

    &_place_about-us {
      @media screen and (max-width: 546px) {
        row-gap: 50px;
      }
    }
  }
}
