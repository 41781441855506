.audio-player {
  display: flex;
  flex-direction: column;

  &__container {
    max-width: 730px;
    height: 52px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 14px;
    box-sizing: border-box;
    background-color: var(--tab-background-color);
    border-radius: 6px;
    position: relative;

    @media screen and (max-width: 810px) {
      max-width: 533px;
      height: 40px;
    }

    @media screen and (max-width: 612px) {
      max-width: 290px;
    }
  }
}
