@import '../../assets/styles/mixins/main-text-font';

.main-menu {
  margin-left: 43px;

  &_place_header {
    @media screen and (max-width: 1126px) {
      display: none;
    }
  }

  &_place_burger {
    position: absolute;
    right: -350px;
    width: 350px;
    height: 100%;
    margin-left: 0;
    background-color: var(--main-background-color);
    box-sizing: border-box;
    padding: 21.14px 39.5px 0 35px;
    display: flex;
    flex-direction: column;
    transition: right 0.3s linear;

    @media screen and (max-width: 546px) {
      width: 235px;
      right: -235px;
      padding-top: 15px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &_is_opened {
    right: 0;
  }

  &__search-link {
    text-decoration: none;
    color: #282f30;
    margin-bottom: 32px;
    @include main-text-font(16, 500);

    align-items: center;
    display: none;

    @media screen and (max-width: 436px) {
      display: flex;
    }

    &:active {
      color: var(--hover-off-active-link-color);

      svg path {
        stroke: var(--hover-off-active-link-color);
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  &__list {
    display: flex;
    list-style: none;
    gap: 24px;
    margin: 0;
    padding: 0;

    &_place_burger {
      flex-direction: column;
      gap: 32px;
      font-size: 0;
    }

    &-link {
      text-decoration: none;
      color: #282f30;
      @include main-text-font(16, 400, 1.25);

      transition: color 0.2s ease-in-out;

      &:not(.main-menu__list-link_active):hover {
        color: var(--hover-off-active-link-color);
      }

      &_active {
        color: var(--active-link-color);
      }

      &_place_burger {
        line-height: 1.3;

        @media screen and (max-width: 546px) {
          line-height: normal;
          display: inline-block;
          max-height: 20px;
        }
      }
    }
  }
}
